import React from 'react';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import useIsMobile from '../../hooks/useIsMobile';

import Agenda from '../Agenda/Agenda';
import Contato from '../Contato/Contato';

import './styles.css';

function Offices({ officesRef }) {
    const isMobile = useIsMobile();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC3jL00kxhv-xs1k-pimGbVZJAU9vzfgqc"
    })

    const containerStyle = {
        width: isMobile ? '80vw' : '25vw',
        height: isMobile ? '75vw' : '25vw',
      };
      
    const botafogo = {
        lat: -22.941920,
        lng: -43.180450
    };

    const tijuca = {
        lat: -22.923940,
        lng: -43.230290
    };

    return(
        <div className="offices-container" ref={officesRef}>
            <h2>Consultórios</h2>
            <div className="maps-container">
                <div className="map-container">
                    {
                        isLoaded ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={botafogo}
                            zoom={15}
                      >
                        <Marker position={botafogo} />
                      </GoogleMap>
                      : false
                    }
                    <h3>Botafogo</h3>
                    <p>Praia de Botafogo, 210 - Sala 504, Botafogo, Rio de Janeiro - RJ</p>
                </div>
                <div className="map-container">
                    {
                        isLoaded ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={tijuca}
                            zoom={15}
                        >
                            <Marker position={tijuca} />
                        </GoogleMap>
                        : false
                    }
                    <h3>Tijuca</h3>
                    <p>Rua Jurupari, 20 - Sala 04, Tijuca, Rio de Janeiro, RJ</p>
                </div>
            </div>
            <Agenda text />
            <p className="or">ou</p>
            <Contato ></Contato>
        </div>
    )
}

export default Offices;