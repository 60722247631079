import React, { useState, useRef } from 'react';

import './styles.css';

import Main from '../../components/Main/Main';
import Header from '../../layouts/Header/Header';
// import WhoAmI from '../../components/WhoAmI/WhoAmI';
import Offices from '../../components/Offices/Offices';
import Footer from '../../layouts/Footer/Footer';

function LandingPage() {

    const [menu, setMenu] = useState(false);

    const whoAmIRef = useRef();
    const officesRef = useRef();

    return(
        <div className="page-container">
            <Header whoAmIRef={whoAmIRef} officesRef={officesRef} menu={menu} setMenu={setMenu} />
            <div className="content-container">
                <Main officesRef={officesRef} menu={menu} />
                {/* <WhoAmI whoAmIRef={whoAmIRef} /> */}
                <Offices officesRef={officesRef} />
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage;