import React from 'react';

import './styles.css';

import Agenda from '../../components/Agenda/Agenda';
import Contato from '../../components/Contato/Contato';

function MenuMobile({ menu, setMenu, whoAmIRef, officesRef }) {

    // const scrollToWhoAmI = () => {
    //     setMenu(false);
    //     window.scrollTo({ behavior: 'smooth', top: whoAmIRef.current.offsetTop - 40 });
    // }
    const scrollToOffices = () => {
        setMenu(false);
        window.scrollTo({ behavior: 'smooth', top: officesRef.current.offsetTop - 40 });
    }

    return(
        <div className={`menu-mobile-container ${menu ? "show" : "hide"}`}>
            {/* <p onClick={scrollToWhoAmI}>Quem sou eu</p> */}
            <p className="item" onClick={scrollToOffices}>Consultórios</p>
            <div className="buttons-container">
                <Contato />
                <Agenda />
            </div>
        </div>
    )
}

export default MenuMobile;