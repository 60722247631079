import React  from 'react';

import Hamburger from 'hamburger-react';
import MenuMobile from '../MenuMobile/MenuMobile';
import Button from './Button';

import './styles.css';
import brain from '../../assets/icons/brain.png';

function Header({ menu, setMenu, whoAmIRef, officesRef }) {

    // const scrollToWhoAmI = () => window.scrollTo({ behavior: 'smooth', top: whoAmIRef.current.offsetTop - 80 });
    const scrollToOffices = () => window.scrollTo({ behavior: 'smooth', top: officesRef.current.offsetTop - 80 });

    return(
        <div className="header-container">
            <MenuMobile whoAmIRef={whoAmIRef} officesRef={officesRef} menu={menu} setMenu={setMenu} />
            <div className="header-left">
                <img alt="main-icon" src={brain} />
                <p>Dr. Pedro Arduini</p>
            </div>
            <div className="header-right-mobile">
                <Hamburger onToggle={setMenu} size={24} toggled={menu} />
            </div>
            <div className="header-right-desktop">
                {/* <p className="link-header" onClick={scrollToWhoAmI}>Quem sou eu</p> */}
                <p className="link-header" onClick={scrollToOffices}>Consultórios</p>
                <Button />
            </div>
        </div>
    )
}

export default Header;