import React from 'react';
import ReactDOM from 'react-dom/client';

// Navigation (used React-Router V6)
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import LandingPage from './routes/LandingPage/LandingPage';

import '../src/assets/styles/index.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
