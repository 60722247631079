import React from 'react';

import './styles.css';

function Contato() {
    return(
        <a 
        href="https://wa.me/5521998128785" 
        target="_blank"
        rel="noreferr noreferrer"
        className="contato-container">
            <p>Entre em contato</p>
        </a>
    )
}

export default Contato;