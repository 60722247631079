import React from 'react';

import './styles.css';

function Button() {
    return(
        <a 
        href="https://wa.me/5521998128785" 
        target="_blank"
        rel="noreferr noreferrer"
        className="header-button-container">
            <p>Entre em contato</p>
        </a>
    )
}

export default Button;