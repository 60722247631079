import React from 'react';

import './styles.css';
import down from '../../assets/icons/down-arrow.png';

function Main({ officesRef, menu }) {

    const scrollToWhoAmI = () => window.scrollTo({ behavior: 'smooth', top: officesRef.current.offsetTop - 80 });

    return(
        <div className="main-container">
            <div className="greet-container">
                <div className="name-container">
                    <h1>Dr. Pedro Henrique<br></br>Arduini Rodrigues</h1>
                    <p className="little-description">Psiquiatria - CRM 010-9169-7</p>
                    <p className="description">Atendimento Ambulatorial de Psiquiatria: Consultórios Tijuca e Botafogo.<br></br><br></br>Graduado em Medicina pela Universidade Gama Filho / Universidade Estácio de Sá e Pós-Graduado em Psiquiatria pela CENBRAP.</p>
                </div>
            </div>
            <div className="brain-and-pic">
                <div className="pic-container" />
            </div>
            { !menu ? <img className="down-arrow" src={down} alt="down-arrow" onClick={scrollToWhoAmI}/> : false }
        </div>
    )
}

export default Main;