import React from 'react';

import './styles.css';
import agenda from '../../assets/icons/agenda.png';

function Agenda({ text, contact }) {
    return(
        <div className="agenda-container">
            { text ? <h3>Acesse minha agenda online e marque sua consulta</h3> : false }
            <a 
                href="https://calendly.com/dr-pedroarduini" 
                target="_blank"
                rel="noreferr noreferrer"
                className="agenda-button">
                <img alt="agenda-icon" src={agenda} />
                <p>Agenda virtual</p>
            </a>
            { contact ? <p className="agenda-contact">ou <span className="underlined-text">Entre em contato</span></p> : false }
        </div>
    )
}

export default Agenda;