import React from 'react';

import './styles.css';
import location from '../../assets/icons/location.png';
import email from '../../assets/icons/email.png';
import telephone from '../../assets/icons/telephone.png';

function Footer() {
    return(
        <div className="footer-container">
            <div className="info-container">
                <p className="name">Dr. Pedro Arduini</p>
                <p className="cnpj">47.767.716/0001-82</p>
            </div>
            <div className="addresses-container">
                <div className="address-container">
                    <img alt="pin" src={location} />
                    <p>Praia de Botafogo, 210 - Sala 504, Botafogo, Rio de Janeiro - RJ</p>
                </div>
                <div className="address-container">
                    <img alt="pin" src={location} />
                    <p>Rua Jurupari, 20 - Sala 04, Tijuca, Rio de Janeiro, RJ</p>
                </div>
            </div>
            <div className="contacts-container">
                <div className="contact-container">
                    <img alt="email" src={email} />
                    <p>dr.pedroarduini@gmail.com</p>
                </div>
                <div className="contact-container">
                    <img alt="telephone" src={telephone} />
                    <p>+55 (21) 99812-8785</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;